.mutation-error-message {
    border: 2px solid rgba(255, 0, 0, 0.5);
    padding: 10px;
    margin: 30px 10px 10px 0;
    border-radius: 10px;
    color: rgba(255, 0, 0, 0.8);
}

.mutation-error-list {
    color: #282828;
    margin-top: 10px;
}

.mutation-error-item {
    font-size: 14px;
}

.mutation-page-container {
    line-break: anywhere;
}
