.histogram-container {
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
}
.histogram-bar-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-top: 12px;
    overflow: visible;
}

.histogram-bar {
    display: flex;
    flex-direction: column;
}

.label {
    transform: rotate(30deg) translate(5px);
    font-family: Roboto, serif;
    margin-top: 3px;
    margin-bottom: 30px;
    text-align: center;
    letter-spacing: 1px;
}

.histogram-export-button {
    display: flex;
    justify-content: flex-end;
    margin: 5px 0px;
}

.histogram-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.histogram-export-button > button + button {
    margin: 0 10px;
}
