.heatmap-legend-wrapper {
    margin-left: 15px;
}

.heatmap-legend-title {
    position: relative;
    left: 30px;
    margin-bottom: 10px;
    font-size: 1.4em;
}

.heatmap-legend-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: -1px;
}

.heatmap-legend-item:hover {
    color: silver;
}

.heatmap-legend-item-tick {
    display: inline-block;
    width: 27px;
    height: 1px;
    background-color: silver;
    margin-right: 5px;
    margin-top: 1px;
}

.heatmap-legend-label-item-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: absolute;
    top: -12px;
    left: -19px;
}

.heatmap-legend-hints-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #aaaaaa;
}

.heatmap-legend-hint {
    transform: translateX(-140px) translateY(-40px) rotate(-90deg);
    width: 190px;
    height: 1em;
}

.heatmap-legend-anchor {
    width: 0;
    height: 0;
}

sub,
sup {
    font-size: 0.5em;
}

sup {
    vertical-align: top;
    position: relative;
    top: 0.7em;
    left: -1.6em;
}
