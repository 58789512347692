.searchbar-container {
    background-color: white;
    border-style: solid;
}

.searchbar-container {
    display: flex;
}

.dropdown-item {
    line-height: 15px;
    font-size: 12px;
    cursor: pointer;
    height: 17px;
    width: 150px;
    text-align: center;
    margin: -7px auto;
}

.dropdown-opener {
    cursor: pointer;
    background-color: white;
    height: 22px;
    margin-top: 3px;
    margin-bottom: -15px;
}

.dropdown-table-wrapper {
    margin-bottom: 10px;
}
