table {
    width: 100%;
    font-family: Sans, serif;
    background-color: white;
    border: 1px solid #ececec;
    padding: 1em;
}

tr {
    width: 100%;
}

tr:nth-child(odd) {
    background-color: WhiteSmoke;
}

td {
    padding: 2px 10px;
}

th {
    background-color: #383838;
    color: white;
    padding: 0.5em 10px;
}

.table-header-content-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.second-table-header {
    background-color: #6b6b6b;
}

td.right-aligned,
th.right-aligned {
    text-align: end;
    padding-right: 50px;
}
