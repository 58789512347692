.main-page {
    background-color: white;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-page-content {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: scroll;
    width: 100%;
}

.contain-this-mess {
    flex-grow: 1;
    flex-shrink: 1;
}

.organism-list {
    display: flex;
    flex-direction: column;
}

.organism-list-content {
    padding: 0 0 0 15px;
}

.organism-list li {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
}

.organism-list li.selectable-li:hover {
    cursor: pointer;
    color: #666666;
}

.organism-list-date {
    font-size: 0.8em;
    color: #666666;
}

.custom-control-label:hover {
    cursor: pointer;
}
