.heatmap-pixel {
    width: 20px;
    height: 20px;
    border: 1px solid silver;
}

.heatmap-pixel:hover {
    box-shadow: 0px 0px 0px 2px blue inset;
    cursor: pointer;
}

.pixel-cognate {
    width: 75%;
    height: 75%;
    border-radius: 50%;
    background-color: #282828;
    opacity: 0.8;
}
