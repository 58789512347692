h1,
p {
    font-family: Lato, serif;
}

.heatmap-row {
    display: flex;
    flex-direction: row;
}

.heatmap-row-label {
    cursor: pointer;
    margin-right: 10px;
    font-size: 0.5em;
    line-height: 22px;
    height: 20px;
    text-align: right;
    width: 50px;
    overflow: visible;
    white-space: nowrap;
}
.heatmap-bottom-label-container {
    display: flex;
    flex-direction: row;
    margin-left: 55px;
}

.heatmap-bottom-label {
    overflow: visible;
    white-space: nowrap;
    width: 20px;
    transform-origin: left;
    transform: translateX(6px) rotate(30deg);
    /*    height: 50px;*/
    font-size: 0.5em;
    font-family: Sans, serif;
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none;
    margin-bottom: 20px;
    margin-top: 2px;
}

.heatmap-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none;
}

.heatmap-title {
    font-size: 1.2em;
    font-family: Sans, serif;
    margin: 20px 20px 20px 20px;
}

.description {
    position: absolute;
    bottom: 10%;
    left: 3%;
    vertical-align: bottom;
}

.HeatmapPage {
}

.plot-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(-20px);
}

.heatmap-whole {
}

.heatmap {
    display: flex;
    flex-direction: column;
    font-family: Sans, serif;
}

.heatmap-legend {
    margin-left: 20px;
    display: flex;
}

.hidden {
    visibility: hidden;
}

.heatmap-bottom-large-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(20px);
}

.heatmap-left-large-label {
    transform: translateX(-0px) rotate(-90deg);
    width: 200px;
    height: 2em;
}

.heatmap-left-large-label-wrapper {
    /*transform: translateX(-0px) rotate(-90deg);*/
    width: 8em;
    overflow: hidden;
    height: 100%;
}

.heatmap-outer-wrapper {
    display: flex;
    flex-direction: row;
}

.heatmap-bottom-large-label,
.heatmap-left-large-label {
    font-weight: bold;
    user-select: none;
}

.click-wrapper:hover {
    cursor: pointer;
    color: DeepSkyBlue;
}

.rr-label-legend {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 65px;
}

.rr-label-anchor {
    width: 0;
    height: 0;
}

.rr-label-contents {
    transform: translateX(-50px) translateY(-40px) rotate(-90deg);
    width: 190px;
    height: 1em;
    font-weight: bold;
    user-select: none;
}
