.histogram-legend-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: -1px;
    justify-content: flex-end;
    color: #282828;
    font-size: 12px;
    user-select: none;
}

.histogram-legend-title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.histogram-legend-title {
    transform: rotate(-90deg);
    color: #282828;
    user-select: none;
}

.histogram-legend-item-tick {
    display: inline-block;
    width: 5px;
    height: 2px;
    background-color: #757575;
    margin-left: 5px;
    margin-top: 1px;
    margin-right: -1px;
}

.histogram-legend-labels-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    /*    position: absolute;*/
    margin-top: 3px;
}

.histogram-x-label {
    font-size: 10px;
    font-weight: bold;
    margin-left: 170px;
    color: #757575;
    margin-bottom: 30px;
}

.histogram-title {
    margin-left: 10px;
    user-select: none;
}
