.heatmap-settings-bar {
    font-size: 2em;
}

.heatmap-settings-bar:hover {
    cursor: pointer;
    color: silver;
}

.form-check-label:hover {
    cursor: pointer;
}
