.drag-box {
}

.drag-box-border {
    background: rgba(0, 162, 255, 0.4);
    position: fixed;
}

.add-button-container {
    margin: auto;
    width: 10%;
    text-overflow: ellipsis;
}

.remove-button-container {
    margin: auto;
    width: 10%;
    text-overflow: ellipsis;
}

.remove-button {
    text-align: center;
    text-overflow: ellipsis;
    height: 22px;
    overflow: hidden;
    border-style: solid;
    background-color: white;
    cursor: pointer;
}

.dragger {
    height: 10px;
    width: 101%;
    background-color: black;
}
